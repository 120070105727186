import React, { useContext, useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useNavigate, useLocation } from "react-router-dom";
import TextWidget from "./TextWidget";
import { TransactionContext } from "../context/TransactionContext";
import LoadingIndicator from "./WidgetModal/LoadingIndicator";

export default function MakePayment({ widgetId }) {
  const paymentIsTwice = () => premium?.data?.paymentType == "TWICE";
  const {
    verifyPaymentLoading,
    customerDetails,
    paymentDetails,
    premium,
    setPremium,
    getPremiumLoading,
    setVerifyPaymentLoading,
    getPremium,
    verifyPayment,
    getPaymentReference,
    paystackTransRef,
  } = useContext(TransactionContext);

  const navigate = useNavigate();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const queryId = searchParams.get("id");
  const paymentType = searchParams.get("paymentType");
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (event) => {
    setIsChecked(event.target.value === "true");
  };

  let NGNaira = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });

  const getPaystackey = () => {
    const isLive = widgetId.includes("LIVE");
    return isLive ? process.env.REACT_APP_PAYSTACK_KEY_LIVE : process.env.REACT_APP_PAYSTACK_KEY_TEST;
  };

  const onSuccess = (reference) => {
    let data = {
      "transId": `${queryId}`,
      "transRef": `${reference.trxref}`,
      "customerEmail": `${customerDetails?.data.email}`,
      "amount": `${paymentIsTwice() ? premium?.data?.altPaymentOne : premium?.data?.firstPayment}`,
    };
    verifyPayment(data);
  };

  // you can call this function anything
  const onClose = (params) => {
    // console.log({ params })
  };

  let config = {};

  if (paystackTransRef !== null) {
    // console.log({ paystackTransRef });
    config = {
      channels: ["card"],
      reference: paystackTransRef?.data,
      // customerDetails?.data.firstName
      email: `${customerDetails?.data.email}`,
      amount: (paymentIsTwice() ? premium?.data?.altPaymentOne : premium?.data?.firstPayment) * 100,
      publicKey: getPaystackey(),
      metadata: {
        custom_fields: [
          {
            display_name: "Customer Name",
            variable_name: "customer_name",
            value: `${customerDetails?.data.firstName} ${customerDetails?.data.lastName}`,
          },
          {
            display_name: "Phone Number",
            variable_name: "phone_number",
            value: `${customerDetails?.data.phoneNumber}`,
          },
        ],
      },
    };
  }

  const initializePayment = usePaystackPayment(config);
  const goBack = () => {
    setPremium(null);
    navigate(-1);
  };

  useEffect(() => {
    getPremium(queryId, paymentType);
    getPaymentReference(queryId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (paymentDetails) {
      navigate(`/payment-success?id=${searchParams.get("id")}`);
    }
  }, [paymentDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal-content bg-white">
      {/* <InitialModalHeader /> */}
      {customerDetails?.data?.env === "test" ? <TextWidget className="mt-2" /> : <></>}
      {paystackTransRef === null || customerDetails === null || verifyPaymentLoading || !premium?.data?.paymentType ? (
        <LoadingIndicator>Processing payment</LoadingIndicator>
      ) : (
        <div className="modal-body p-3">
          <div className="">
            <h2 className="text-center mb-1 fw-bold mt-0">Comprehensive Auto Cover</h2>
            <div className="text-center">
              <small>Pay for your insurance in 2 or 4 installments</small>
            </div>
          </div>

          <div className="row g-5 mt-3">
            <div className="col-md-6 col-lg-6" style={{ margin: "auto" }}>
              <div>
                <h3 className="text-center mb-1 fw-bolder text-primary octamile-blue">{NGNaira.format(paymentIsTwice() ? premium?.data?.premium : premium?.data?.totalAmount)}</h3>
                <h6 className="text-center mb-3 fw-regular">Total amount</h6>
                <hr className="first-hr" />
                <div className="text-center">{!paymentIsTwice() && <small>You will be charged a 6% flat fee for this service.</small>}</div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <form>
                <div className="row g-3">
                  <div className="col-12">
                    <ul className="list-group mb-3">
                      <li className="list-group-item d-flex justify-content-between lh-sm" style={{ padding: "1em" }}>
                        <div>
                          <h5 className="my-0 octamile-blue">Pay now:</h5>
                          <small className="text-body-secondary">{new Date().toDateString()}</small>
                        </div>
                        <span
                          className="text-primary"
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "600",
                          }}
                        >
                          {NGNaira.format(paymentIsTwice() ? premium?.data?.altPaymentOne : premium?.data?.firstPayment)}
                        </span>
                      </li>
                      <small className="mt-1" style={{ fontSize: "0.7em" }}>
                        {" "}
                        Plus charge fee: <span className="mt-1 text-primary">{NGNaira.format(paymentIsTwice() ? 0 : premium?.data?.chargeFee)} </span>
                      </small>
                    </ul>
                  </div>

                  <div className="col-12">
                    <ul className="list-group mb-3">
                      <Section
                        title={paymentIsTwice() ? "Final payment" : "Second payment"}
                        date={paymentIsTwice() ? premium?.data?.altPaymentDate : premium?.data?.secondPaymentDate}
                      >
                        {NGNaira.format(paymentIsTwice() ? premium?.data?.altPaymentTwo : premium?.data?.secondPayment)}
                      </Section>

                      {!!premium?.data?.thirdPayment && (
                        <Section title="Third payment" date={premium?.data?.thirdPaymentDate}>
                          {NGNaira.format(premium?.data?.thirdPayment)}
                        </Section>
                      )}

                      {!!premium?.data?.fourthPayment && (
                        <Section title="Fourth payment" date={premium?.data?.fourthPaymentDate}>
                          {NGNaira.format(premium?.data?.fourthPayment)}
                        </Section>
                      )}
                    </ul>
                  </div>

                  <div className="col-12 mt-3">
                    <label className="form-label d-flex">
                      <input type="radio" value="true" checked={isChecked} onChange={handleChange} />
                      <div style={{ marginLeft: "0.5em" }}>
                        <small>I agree to Octamile automatically charging from my card</small>
                      </div>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="row g-3 py-3">
            <div className="d-flex justify-content-between align-items-center pt-3">
              <button className="col-4 btn btn-light btn-lg btn-right" onClick={goBack}>
                Go back
              </button>

              <button
                className="col-7 btn btn-primary btn-lg btn-right"
                type="submit"
                disabled={!isChecked}
                onClick={() => {
                  initializePayment(onSuccess, onClose);
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Section({ title, date, children }) {
  return (
    <li className="list-group-item d-flex justify-content-between lh-sm align-items-center">
      <div>
        <h6 className="mt-0">{title}</h6>
        <small className="text-body-secondary">{date}</small>
      </div>
      <span className="text-secondary">{children}</span>
    </li>
  );
}
