import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TransactionContext } from "../context/TransactionContext";
import InfoModal from "./WidgetModal/InfoModal";
import CloseButton from "./WidgetModal/CloseButton";

const VerificationScreen = () => {
  const { customerDetails, transStatusCheck, statusCheck, updateStatusTimeout, setTransStatusCheck } = useContext(TransactionContext);
  const navigate = useNavigate();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const queryId = searchParams.get("id");

  const Eligibility = () => {
    return (
      <InfoModal icon="/checking.gif" title="Confirming your Eligibility" secondary={`This will only take a moment`} caption="Kindly hold on, do not close this window"></InfoModal>
    );
  };

  const Decline = () => {
    return (
      <InfoModal icon="/error.gif" caption="At the moment, we are unable to verify your information. Kindly contact business@octamile.com for further assistance">
        <CloseButton />
      </InfoModal>
    );
  };

  const SuccessComplete = () => {
    return <InfoModal icon="/success.gif" caption="Congratulations! your credit score check was successful." />;
  };

  const Success = () => {
    const [paymentType, setPaymentType] = useState("TWICE");

    return (
      <InfoModal icon="/success.gif" title="Credit score check successful" caption="To make budgeting easier, how many months would you prefer to split your payment into?">
        <>
          <div className="w-100 h6">
            <hr />
            <label className="d-flex" onClick={() => setPaymentType("TWICE")}>
              <input type="radio" name="paymentType" defaultChecked={true} />
              <div className="mx-3 d-flex justify-content-between align-items-center w-100">
                <div className="">Two Installments </div>
                <small className="text-secondary">Zero Fees</small>
              </div>
            </label>
            <hr />

            <label className="d-flex py-3-" onClick={() => setPaymentType("FOURTH")}>
              <input type="radio" name="paymentType" />
              <div className="mx-3 d-flex justify-content-between align-items-center w-100">
                <div className="">Four Installments </div>
                <small className="text-secondary">6% Fee</small>
              </div>
            </label>
            <hr />
          </div>

          <button className="w-50 btn btn-primary btn-lg mt-2" onClick={() => navigate(`/make-payment?id=${searchParams.get("id")}&paymentType=${paymentType}`)}>
            Proceed
          </button>
        </>
      </InfoModal>
    );
  };

  const displayType = () => {
    switch (transStatusCheck) {
      case "pending":
        return <Eligibility />;
      case "approved":
        return <Success />;
      case "declined":
        return <Decline />;
      case "timeout":
        return <Decline />;
      case "abandoned":
        return <Decline />;
      case "successful":
        return <SuccessComplete />;
      default:
        return <Eligibility />;
    }
  };

  useEffect(() => {
    statusCheck(queryId);
  }, [transStatusCheck]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal-content">
      {/* {customerDetails?.data?.env === 'test' ? <TextWidget className="mt-2" /> : <></>} */}
      <div className="modal-body p-md-3 mt-3">
        <div className="col-md-12 col-lg-12">{displayType()}</div>
      </div>
    </div>
  );
};

export default VerificationScreen;
