export default function CloseButton() {
  return (
    <button className="w-50 btn btn-primary btn-lg mt-2" onClick={closeWidget}>
      Close
    </button>
  );
}

export const closeWidget = () => {
  window.parent.parent.postMessage("*PAYCOVER_CLOSE_WIDGET", "*");
};
