import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultLayout } from "./DefaultView";
import { useState } from "react";
import InfoModal from "../components/WidgetModal/InfoModal";
import LoadingIndicator from "../components/WidgetModal/LoadingIndicator";

export default function FirstPayment() {
  const urlParams = new URLSearchParams(window.location.search);
  const transId = urlParams.get("transId");
  const paymentType = urlParams.get("paymentType");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const verifyTransaction = async (params) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}verify-customer-identity?transId=${transId}&paymentType=${paymentType}`)
      .then((res) => {
        const merchantKey = res.data?.merchantData?.merchantKey;
        navigate(`/${merchantKey}/${transId}`);
      })
      .catch((error) => {});
    setLoading(false);
  };

  useEffect(() => {
    verifyTransaction();
  }, []);

  return (
    <div className="bg-white- min-vh-100">
      <DefaultLayout>
        <div className="widget">
          {loading ? (
            <LoadingIndicator notIframe>Processing. Please wait.</LoadingIndicator>
          ) : (
            <InfoModal
              notIframe
              icon="/error.gif"
              caption="At the moment, we are unable to verify your information. Kindly contact business@octamile.com for further assistance"
            ></InfoModal>
          )}
        </div>
      </DefaultLayout>
    </div>
  );
}
