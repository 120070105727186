import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import * as yup from "yup";
import Select from "react-select";
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useLocation } from "react-router-dom";
import { changeKeys, bankKeyMap } from "../utilities/utils";
import { TransactionContext } from "../context/TransactionContext";
import TextWidget from "./TextWidget";
import LoadingIndicator from "./WidgetModal/LoadingIndicator";
import InfoModal from "./WidgetModal/InfoModal";
import CloseButton from "./WidgetModal/CloseButton";

export default function InitialStep({ widgetId }) {
  // let merchantKey = widgetId;
  const navigate = useNavigate();
  const search = useLocation().search;

  const { customerDetails, verifyCustomerDetails, customerDetailsExists, saveBankDetails, bankDetailsLoading, bankDetails, setMerchantKey, verifyCustomerLoading } =
    useContext(TransactionContext);

  // widgetId

  const [banksOptions, setBanksOptions] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const searchParams = new URLSearchParams(search);
  const queryId = searchParams.get("id");

  const handleChange = (event) => {
    setIsChecked(event.target.value === "true");
  };

  const schema = yup.object({
    bank: yup.string().required("Field is required"),
    accountNumber: yup.string().required("Field is required"),
    bvn: yup.string().required("Field is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    field: { value: bank, onChange: bankOnChange, ...bankField },
  } = useController({ name: "bank", control });

  /// Fetch Banks
  const fetchBanks = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}get-banks`, {
        headers: {
          "merchant-key": widgetId,
        },
      })
      .then(function (response) {
        const bookingOptions = changeKeys(response.data.data.data, bankKeyMap);
        setBanksOptions(bookingOptions);
      })
      .catch(function (error) {});
  };

  const onSubmit = (data) => {
    const { bank, bvn, accountNumber } = data;
    if (!bankDetailsLoading) {
      let filteredBank = banksOptions.filter(function (item) {
        return item.value.toString() === bank.toString();
      });

      let params = {
        "bankName": filteredBank[0].label,
        "accountNumber": accountNumber,
        "transId": `${searchParams.get("id")}`,
        "bankCode": bank,
        "bvn": bvn,
      };

      saveBankDetails(params);
    }
  };

  useEffect(() => {
    setMerchantKey(widgetId);
    fetchBanks();
    verifyCustomerDetails(`${searchParams.get("id")}`, widgetId);
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bankDetails) {
      navigate(`/verify?id=${searchParams.get("id")}`);
    }
  }, [bankDetails]);

  if (queryId === null) {
    return <InvalidId queryId={queryId === null} />;
  }

  // console.log(customerDetails.data.env);
  // let { env } = customerDetails?.data;
  // console.log({ env })
  // console.log(customerDetailsExists?.data)
  // console.log('ENV: ', customerDetailsExists.data?.env)
  // console.log('ENV: ', customerDetailsExists.environment)

  return (
    <div className="modal-content">
      {(verifyCustomerLoading && !customerDetailsExists) || bankDetailsLoading ? (
        <LoadingIndicator>Confirming your eligibility</LoadingIndicator>
      ) : !customerDetailsExists ? (
        <InvalidId queryId={customerDetailsExists} />
      ) : (
        <div className="modal-body p-md-3">
          {customerDetails?.data?.env === "test" ? <TextWidget /> : <></>}
          <h2 className="text-center mb-3 fw-bold mt-3">Comprehensive Auto Cover</h2>
          <p className="text-center small">Pay for your insurance in 2 or 4 installments</p>
          <hr className="first-hr" />
          <div className="col-md-12 col-lg-12">
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 justify-content-center">
                <div className="col-12">
                  <h5 className="text-center mb-3 font-weight-normal px-5">Qualification Check</h5>
                  <p className="text-center small">Kindly provide your account number to check if you are qualified for this product.</p>
                  <hr className="second-hr" />
                </div>

                <div className="col-12">
                  <label htmlFor="email" className="form-label fw-bold">
                    Bank Name
                  </label>
                  <Select
                    options={banksOptions}
                    value={bank ? banksOptions.find((x) => x.value === bank) : bank}
                    onChange={(option) => bankOnChange(option ? option.value : option)}
                    classNamePrefix="react-select"
                    className="banks-select"
                    {...bankField}
                  />
                  {errors.bank && <span className="invalid">{errors.bank.message}</span>}
                </div>

                <div className="col-12">
                  <label htmlFor="email" className="form-label fw-bold">
                    Account Number{" "}
                  </label>
                  <input type="text" className="form-control" maxLength={10} placeholder="000000000" {...register("accountNumber")} />
                  {errors.accountNumber && <span className="invalid">{errors.accountNumber.message}</span>}
                </div>

                <div className="col-12">
                  <label htmlFor="bvn" className="form-label fw-bold">
                    BVN
                  </label>
                  <input type="text" maxLength={11} className="form-control" placeholder="0000 0000 0000 0000" {...register("bvn")} />
                  {errors.bvn && <span className="invalid">{errors.bvn.message}</span>}
                </div>

                <div className="col-12">
                  <label className="form-label d-flex">
                    <input type="radio" value="true" checked={isChecked} onChange={handleChange} />
                    <div style={{ marginLeft: "0.5em" }}>I agree to you running a credit check on my account</div>
                  </label>
                </div>
              </div>

              <div className="row g-3 mt-1">
                <div className="col-md-5 col-lg-4 order-md-last">
                  <button className="btn btn-primary btn-lg btn-right w-100" type="submit" disabled={!isChecked}>
                    {bankDetailsLoading ? "Loading..." : "Continue"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export function InvalidId() {
  return (
    <InfoModal icon="/error.gif">
      <p className="text-secondary py-3">
        At the moment, we are unable to verify your information. Kindly contact <a href="mailto:business@octamile.com">business@octamile.com</a> for further assistance
      </p>

      <CloseButton />
    </InfoModal>
  );
}
