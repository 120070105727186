import { useParams } from "react-router-dom";
import "./pages.scss";
import ModalFooter from "../components/ModalFooter/ModalFooter";
import { closeWidget } from "../components/WidgetModal/CloseButton";

export default function DefaultView() {
  let { widget_id, id } = useParams();

  return (
    <DefaultLayout close={closeWidget}>
      <iframe src={`/?&id=${id}#${widget_id}`} title="paycover" className="shadow-lg" />
    </DefaultLayout>
  );
}

export function DefaultLayout({ children }) {
  return (
    <main className="widget-container">
      <div className="default-view-page d-flex justify-content-center align-items-center">
        <div className="close-button-container d-flex justify-content-end">
          <button className="close-button shadow d-flex justify-content-center align-items-center" onClick={closeWidget}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <>{children}</>
        <ModalFooter />
      </div>
    </main>
  );
}
