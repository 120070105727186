import React, { useContext } from "react";
import { TransactionContext } from "../context/TransactionContext";
import LoadingIndicator from "./WidgetModal/LoadingIndicator";
import { closeWidget } from "./WidgetModal/CloseButton";

const PaymentSuccessful = ({ widgetId }) => {
  const { customerDetails, verifyCustomerLoading, paymentDetails, verifyCustomerDetails } = useContext(TransactionContext);

  let NGNaira = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });

  return (
    <div className="modal-content">
      {verifyCustomerLoading ? (
        <LoadingIndicator>Confirming payment</LoadingIndicator>
      ) : (
        <>
          <div className="modal-body  p-md-3">
            <h2 className="text-center mb-3 fw-bold mt-3">Comprehensive Auto Cover</h2>
            <p className="text-center small">Pay for your insurance in 2 or 4 installments</p>
            <hr className="first-hr" />
            <div className="col-12 mx-auto p-3">
              <div className="w-full text-center">
                <>
                  <img width={100} height={100} src="/success.gif" alt="ddf" className="d-print-none col" />

                  <h4 className="align-items-center py-3">
                    <span className="text-primary">Payment Successful</span>
                  </h4>

                  <ul className="card list-group w-100 mb-3">
                    <Section title="Payment Type">Internet Banking</Section>
                    <Section title="Bank">{paymentDetails?.data?.bankName}</Section>
                    <Section title="Email">{paymentDetails?.data?.email}</Section>
                    <Section title="Amount paid">
                      <div className="text-primary">{NGNaira.format(paymentDetails?.data?.amount ?? 0)}</div>
                    </Section>
                    <Section title="Transaction Id">{paymentDetails?.data?.transId}</Section>
                  </ul>
                </>
              </div>
            </div>

            <div className="row gy-3 mt-3 justify-content-center">
              <div className="col-md-8 d-flex justify-content-center text-center">
                <button className="btn btn-danger btn-lg mx-2" onClick={closeWidget}>
                  Close
                </button>
                <button className="btn btn-primary btn-lg px-5" onClick={() => window.print()}>
                  Print
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentSuccessful;

function Section({ title, children }) {
  return (
    <li className="list-group-item d-flex justify-content-between lh-sm p-3">
      <h6 className="my-0">{title}:</h6>
      <span className="text-body-secondary">{children}</span>
    </li>
  );
}
