export default function InfoModal({ icon, title, secondary, caption, children, notIframe }) {
  return (
    <div className={`d-flex align-items-center justify-content-center flex-column text-center p-3 h-full my-auto ${notIframe ? "h-100" : "vh-100"}`}>
      <img src={icon} alt="icon" style={{ width: "12em" }} />
      {title && <div className="fs-5 fw-semibold my-0 pt-3">{title}</div>}
      {secondary && <div className="fs-5 fw-medium text-primary pt-2">{secondary}</div>}
      <p className="text-secondary py-3">{caption}</p>
      <>{children}</>
    </div>
  );
}
