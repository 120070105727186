import React, { useState, createContext } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
// import { changeKeys, bankKeyMap } from '../utilities/utils';

/// Live
const API_URL = process.env.REACT_APP_API_URL;

export const TransactionContext = createContext();
export const TransactionProvider = props => {
    // let merchantKey = 'pc-69de648c372921af';
    const [merchantKey, setMerchantKey] = useState(null);
    const [bankDetails, setBankDetails] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [verifyPaymentLoading, setVerifyPaymentLoading] = useState(false);
    const [premium, setPremium] = useState(null);
    const [getPremiumLoading, setGetPremiumLoading] = useState(false);
    const [bankDetailsLoading, saveBankDetailsLoading] = useState(false);
    const [verifyAccount, setVerifyAccount] = useState(null);
    const [transStatusCheck, setTransStatusCheck] = useState(null);
    // const [banksOptions, setBanksOptions] = useState([]);
    const [paystackTransRef, setPaystackTransRef] = useState(null);

    const [customerDetails, setCustomerDetails] = useState(null);
    const [customerDetailsExists, setCustomerDetailsExists] = useState(false);
    const [verifyCustomerLoading, setVerifyCustomerLoading] = useState(false);

    /// Fetch Banks
    const saveBankDetails = (params) => {
        saveBankDetailsLoading(true);
        axios.post(`${API_URL}save-bank-account`, params, {
            headers: {
                'merchant-key': merchantKey
            }
        })
            .then(function (response) {
                saveBankDetailsLoading(false);
                setBankDetails(response.data)

            })
            .catch(function (error) {
                saveBankDetailsLoading(false);
                toast.error('Eligibility Error: ')
            });
    }

    /// Verify customer details
    const getPremium = (transId, paymentType) => {
        setGetPremiumLoading(true);
        axios.get(`${API_URL}get-premium?trans_id=${transId}&paymentType=${paymentType}`, {
            headers: {
                'merchant-key': merchantKey
            }
        })
            .then(function (response) {
                setPremium(response.data);
                setGetPremiumLoading(false);
            })
            .catch(function (error) {
                setGetPremiumLoading(false);
            });
    }

    /// Verify customer details
    const verifyCustomerDetails = (transId, key) => {
        setVerifyCustomerLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}check-trans-id?trans_id=${transId}`, {
            headers: {
                'merchant-key': key
            }
        })
            .then(function (response) {
                setCustomerDetails(response.data)
                setVerifyCustomerLoading(false);
                setCustomerDetailsExists(true)
            })
            .catch(function (error) {
                setCustomerDetailsExists(false)
                setVerifyCustomerLoading(false);
                window.parent.postMessage("*PAYCOVER_CREDIT_CHECK_INVALID", "*");
            });
    }

    // Credit check (with users BVN)
    const statusCheck = async (params) => {
        const statuses = {
            invalid_id: '*PAYCOVER_CREDIT_CHECK_INVALID',
            approved: '*PAYCOVER_CREDIT_CHECK_SUCCESS',
            timeout: '*PAYCOVER_CREDIT_CHECK_TIMEOUT',
            declined: '*PAYCOVER_CREDIT_CHECK_DECLINED',
            failed: '*PAYCOVER_CREDIT_CHECK_FAILED'
        }

        await axios.get(`${API_URL}check-credit-history?transId=${params}`, {
            headers: {
                'merchant-key': merchantKey
            }
        }).then(function (response) {
            const status = 'approved'; //response?.data?.status
            setTransStatusCheck(status)
            window.parent.postMessage(statuses[status], "*");
        }).catch(function (error) {
            const status = 'declined';
            setTransStatusCheck(status)
            window.parent.postMessage(statuses[status], "*");
        });
    }

    /// Update Status timeout
    const updateStatusTimeout = (params) => {
        axios.post(`${API_URL}status-timeout`, params, {
            headers: {
                'merchant-key': merchantKey
            }
        })
            .then(function (response) {
                setVerifyAccount(response.data.data)
            })
            .catch(function (error) { });
    }

    // Update Status timeout
    const verifyPayment = (params) => {
        setVerifyPaymentLoading(true);
        axios.post(`${API_URL}verify-payment`, params, {
            headers: {
                'merchant-key': merchantKey
            }
        })
            .then(function (response) {
                window.parent.window.parent.postMessage("PAYCOVER_TRANSACTION_SUCCESS", "*");
                setPaymentDetails(response.data);
                setVerifyPaymentLoading(false);
            })
            .catch(function (error) {
                setVerifyPaymentLoading(false);
                window.parent.window.parent.postMessage("PAYCOVER_TRANSACTION_FAILED", "*");
            });
    }

    const getPaymentReference = (params) => {
        axios.get(`${API_URL}payment-reference?trans_id=${params}`, {
            headers: {
                'merchant-key': merchantKey
            }
        })
            .then(function (response) {
                setPaystackTransRef(response.data);

            })
            .catch(function (error) {
            });
    }

    return (
        <TransactionContext.Provider
            value={{
                setMerchantKey,
                merchantKey,
                saveBankDetails,
                verifyAccount,
                bankDetailsLoading,
                getPremium,
                transStatusCheck,
                statusCheck,
                updateStatusTimeout,
                setTransStatusCheck,
                premium,
                setPremium,
                getPremiumLoading,
                setVerifyPaymentLoading,
                verifyPayment,
                getPaymentReference,
                paymentDetails,
                paystackTransRef,
                bankDetails,
                verifyPaymentLoading,

                customerDetails,
                customerDetailsExists,
                verifyCustomerLoading,
                verifyCustomerDetails,
            }}>
            {props.children}
        </TransactionContext.Provider>
    )
}

export default TransactionProvider;