export const changeKeys = (array, keyMap) => {
    return array.map(obj => {
        const modifiedObj = {};
        for (let key in obj) {
            if (keyMap.hasOwnProperty(key)) {
                modifiedObj[keyMap[key]] = obj[key];
            } else {
                modifiedObj[key] = obj[key];
            }
        }
        return modifiedObj;
    });
}

export const bankKeyMap = {
    code: 'value',
    name: 'label',
};

export const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&'); //eslint-disable-line
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};