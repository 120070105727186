export default function SamplePage() {
  const { showPaycoverWidget } = window.parent;

  const paycoverCallbacks = {
    onOpen: () => console.log("Paycover Widget opened"),
    onClose: () => console.log("Paycover Widget closed"),
    onCreditCheckFailed: (status) => console.log("onCreditCheckFailed: ", status),
    onCreditCheckSuccess: (status) => console.log("onCreditCheckSuccess: ", status),
    onTransactionFailed: (status) => console.log("onTransactionFailed: ", status),
    onTransactionSuccess: (status) => console.log("onTransactionSuccess: ", status),
  };

  const openWidget = () => {
    showPaycoverWidget("TRANSACTION_ID", paycoverCallbacks);
  };

  return (
    <main style={{ padding: "1em" }}>
      <div style={{ paddingBottom: "1em" }}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem quam, distinctio laboriosam dicta culpa soluta natus sequi voluptate repudiandae facilis, ratione corrupti
        doloremque unde, praesentium dolore atque ab! Blanditiis, earum!Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem quam, distinctio laboriosam dicta culpa
        soluta natus sequi voluptate repudiandae facilis, ratione corrupti doloremque unde, praesentium dolore atque ab!
      </div>

      <button onClick={openWidget} style={{ padding: "1em" }}>
        Pay with paycover
      </button>
    </main>
  );
}
