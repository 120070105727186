import axios from "axios";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DefaultLayout } from "./DefaultView";
import { useState } from "react";
import { usePaystackPayment } from "react-paystack";
import InfoModal from "../components/WidgetModal/InfoModal";
import LoadingIndicator from "../components/WidgetModal/LoadingIndicator";
import { closeWidget } from "../components/WidgetModal/CloseButton";
import { TransactionContext } from "../context/TransactionContext";

export default function LiquidateLoan() {
  let { transId } = useParams();
  const noInstallment = false; // #noInstallment
  const { verifyPaymentLoading, paymentDetails, setMerchantKey, verifyPayment } = useContext(TransactionContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [config, setConfig] = useState({});
  const [customerData, setCustomerData] = useState();

  const verifyTransaction = async (params) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}verify-user?transId=${transId}`)
      .then((res) => {
        const customerData = res.data?.customerData;
        const { email, env, firstName, lastName, paymentReference, remainBalance } = customerData;
        setCustomerData(customerData);

        if (+remainBalance <= 100) {
          setError(true);
          return;
        }

        const { merchantKey } = res.data?.merchantData;
        setMerchantKey(merchantKey);

        setConfig({
          email,
          firstname: firstName,
          lastname: lastName,
          amount: remainBalance * 100,
          reference: paymentReference,
          publicKey: env == "test" ? process.env.REACT_APP_PAYSTACK_KEY_TEST : process.env.REACT_APP_PAYSTACK_KEY_LIVE,
        });
      })
      .catch((error) => {
        setError(true);
      });
    setLoading(false);
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    const { email, paymentReference, remainBalance } = customerData;

    let data = {
      transId,
      "customerEmail": email,
      "amount": remainBalance + "",
      "transRef": paymentReference,
    };
    verifyPayment(data);
  };

  const onClose = () => {
    console.log("closed paystack");
  };

  let NGNaira = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });

  useEffect(() => {
    verifyTransaction();
  }, []);

  useEffect(() => {
    if (paymentDetails) {
      navigate(`/payment-success?id=${transId}`);
    }
  }, [paymentDetails]);

  return (
    <div className="modal-content">
      {loading || verifyPaymentLoading ? (
        <LoadingIndicator notIframe>Processing. Please wait.</LoadingIndicator>
      ) : error ? (
        <InfoModal
          notIframe
          icon={+customerData?.remainBalance <= 100 ? "/success.gif" : "/error.gif"}
          caption={
            +customerData?.remainBalance <= 100
              ? "Your comprehensive motor insurance payment has been completed"
              : "At the moment, we are unable to verify your information. Kindly contact business@octamile.com for further assistance"
          }
        ></InfoModal>
      ) : (
        <InfoModal icon="/success.gif" title="Complete your payment" caption="Protect what matters with comprehensive insurance. Complete your payment now.">
          <form className="w-100 ">
            <div className="col-12">
              <ul className="list-group mb-3">
                <Section title="Name:">
                  {customerData.firstName} {customerData.lastName}
                </Section>
                <Section title="Email:">{customerData.email}</Section>
                <li className="list-group-item d-flex justify-content-between lh-sm" style={{ padding: "1em" }}>
                  <div>
                    <h5 className="my-0 octamile-blue">Balance:</h5>
                  </div>
                  <span
                    className="text-primary"
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "600",
                    }}
                  >
                    {NGNaira.format(customerData.remainBalance)}
                  </span>
                </li>
              </ul>
            </div>
          </form>

          <div className="pt-5">
            <button
              className="w-100 btn btn-primary btn-lg"
              type="submit"
              onClick={() => {
                initializePayment(onSuccess, onClose);
              }}
            >
              Complete Payment
            </button>
          </div>
        </InfoModal>
      )}
    </div>
  );
}

export function LiquidateLoanPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const transId = urlParams.get("transId");

  return (
    <DefaultLayout close={closeWidget}>
      <iframe src={`/liquidate/${transId}#noInstallment`} title="paycover" className="shadow-lg" />
    </DefaultLayout>
  );
}

function Section({ title, children }) {
  return (
    <li className="list-group-item d-flex justify-content-between lh-sm align-items-center">
      <div>
        <h6 className="mt-0">{title}</h6>
      </div>
      <span className="text-secondary">{children}</span>
    </li>
  );
}
