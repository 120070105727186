import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { TransactionProvider } from './context/TransactionContext';

import InitialStep from "./components/InitialStep";
import PaymentSuccessful from "./components/PaymentSuccessful";
import MakePayment from "./components/MakePayment";
import VerificationScreen from "./components/VerificationScreen";
import DefaultView from "./pages/DefaultView";

import SamplePage from "./pages/SamplePage";
import FirstPayment from "./pages/FullPayment";
import LiquidateLoan, { LiquidateLoanPage } from "./pages/LiquidateLoan";

export default function App() {
  const { hash } = window.location
  const widgetId = hash.substring(1)

  return (
    <TransactionProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<InitialStep widgetId={widgetId} />} />
          <Route path="/:widget_id/:id" element={<DefaultView widgetId={widgetId} />} />
          <Route path="/verify" element={<VerificationScreen />} />
          <Route path="/make-payment" element={<MakePayment widgetId={widgetId} />} />
          <Route path="/payment-success" element={<PaymentSuccessful widgetId={widgetId} />} />

          <Route path="/pay" element={<FirstPayment />} />
          <Route path="/liquidate" element={<LiquidateLoanPage />} />
          <Route path="/liquidate/:transId" element={<LiquidateLoan />} />

          <Route path="/sample" element={<SamplePage />} />
        </Routes>
      </BrowserRouter>
    </TransactionProvider>
  );
}
